<template>
  <div class="violations-create">
    <div class="violations-create__header">
      <r-back-button @back="back" />
      <r-title>Создание нарушение</r-title>
    </div>
    <div class="violations-create__block">
      <r-text>Вид нарушения</r-text>
      <el-select
        v-model="violationType"
        class="r-select"
        filterable
        clearable
        placeholder="Выберите тип">
        <el-option
          v-for="item in violationTypes"
          :key="item.id"
          :value="item.id"
          :label="item.name" />
      </el-select>
      <div class="violations-create__row">
        <el-switch
          v-model="isEditorOdd"
          :disabled="oddDisabled"
          class="r-switch" />
        <r-text color-type="secondary">
          Выбрать объект из Редактора ОДД
        </r-text>
      </div>
    </div>

    <div
      v-if="isEditorOdd"
      class="violations-create__block">
      <r-text>Событие из редактора ОДД</r-text>
      <el-select
        v-model="oddObjectId"
        class="r-select"
        filterable
        clearable
        placeholder="Выберите тип">
        <el-option
          v-for="item in events"
          :key="item.id"
          :value="item.id"
          :label="item.name" />
      </el-select>
    </div>

    <div
      v-if="isAccident && oddObjectId"
      class="violations-create__block">
      <r-text>Нарушитель</r-text>
      <el-select
        v-model="intruderId"
        class="r-select"
        filterable
        clearable
        placeholder="Выберите тип">
        <el-option
          v-for="item in intruders"
          :key="item.id"
          :value="item.id"
          :label="item.name" />
      </el-select>
    </div>

    <r-button
      type="success"
      class="violations-create__button"
      :disabled="disabled"
      :loading="loading"
      @click="createViolation">
      Создать
    </r-button>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import { accidentTypes, sources } from './config'

export default {
  data() {
    return {
      loading: false,
      violationType: null,
      isEditorOdd: false,
      oddObjectId: null,
      intruderId: null,
      sources
    }
  },
  computed: {
    source_id () {
      return this.$store.state.violations.source_id
    },
    related() {
      return this.$store.state.violations.related || []
    },
    violationTypes() {
      return this.related?.type?.data || []
    },
    isAccident() {
      if (!this.violationType) return false

      const violationName = this.violationTypes.find(
        e => e.id === this.violationType
      )?.name

      return accidentTypes.includes(violationName)
    },
    isClosure() {
      if (!this.violationType) return false

      const violationId = this.violationTypes.find(
        e => e.name === 'Нарушение УДС'
      )?.id

      return this.violationType === violationId
    },
    intruders() {
      return this.events.find(
        e => e.id === this.oddObjectId
      )?.trafficaccident?.intruders || []
    },
    events() {
      if (this.isAccident) {
        return this.related?.event?.data.filter(
          e => e.event_class?.name === 'ДТП'
        )
      } else {
        return this.related?.event?.data.filter(
          e => e.event_class?.name === 'Недостатки'
        )
      }
    },
    oddDisabled() {
      return !this.isClosure && !this.isAccident
    },
    disabled() {
      return !this.violationType
    }
  },
  watch: {
    violationType(val) {
      if (!val) {
        this.isEditorOdd = false
      }
      this.oddObjectId = null
      this.intruderId = null
    }
  },
  methods: {
    async createViolation() {
      this.loading = true

      try {
        const data = {
          type_id: this.violationType
        }
        if (this.oddObjectId) {
          data.event_id = this.oddObjectId
        }
        if (this.intruderId) {
          data.intruder_id = this.intruderId
        }
        const { data: response } = await this.$store.dispatch('POST_REQUEST', {
          url: 'BddViolation',
          data
        })
        const violationId = response?.id

        this.$router.push(`/violations/edit?id=${violationId}`)

        const message = 'Нарушение создано'
        this.$notify(notifyFactory('succcess', message))
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    back () {
      this.$router.push('/violations/list')
    }
  }
}
</script>

<style lang="scss">
.violations-create {
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  width: 100%;
  grid-gap: 1rem;
  padding: 0 0.5rem;

  height: 100%;
  justify-content: stretch;
  position: relative;
  align-content: start;

  &__header {
    position: relative;
    padding: 0.5rem;

    .r-title {
      text-align: center;
    }

    button {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &__block {
    display: grid;
    grid-gap: 0.5rem;
    align-content: start;
  }

  &__row {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-content: start;
    align-items: center;
  }

  &__button {
    justify-self: start;
  }
}
</style>
